import React from 'react';
import PortableText from 'react-portable-text';

import {
  Grid,
  Container,
  Typography,
  Theme,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#F5F9FF',
    lineHeight: '0',
    height: 'auto',
    padding: '4rem 0',
    marginTop: '8rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0',
    },
  },
  rootContainer: {
    padding: '6rem 0 16rem 0',
    [theme.breakpoints.down('md')]: {
      padding: '2rem 0 10rem 0',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0rem 0 8rem 0',
    },
  },
  header: {
    color: '#002D5C',
    fontSize: '2.5rem',
    fontWeight: 700,
    textAlign: 'center',
    marginTop: '3.5em',
    marginBottom: '30px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.9rem',
      marginBottom: '0px',
    },
  },

  card: {
    boxShadow: '0px 10px 40px 0px rgba(0, 0, 0, 0.1)',
    background: '#fff',
    borderRadius: '20px',
    border: '2px solid #E5EAF4',
    width: '100%',
    height: '100%',
    backgroundPosition: 'center',
    backgroundSize: '110%',
    backgroundRepeat: 'no-repeat',
    transition: 'all 0.5s',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 15px',

    '&:hover': {
      backgroundSize: '120%',
    },
    '&:focus': {
      backgroundSize: '120%',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '15px 0',
    },
  },

  cardNumber: {
    fontSize: '5rem',
    lineHeight: 1,
    margin: '0',
    padding: '0',

    [theme.breakpoints.down('sm')]: {
      fontSize: '4rem',
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: '2.75rem',
    },
  },
  cardNumber1: {
    color: '#055291',
  },
  cardNumber2: {
    color: '#2A7ABC',
  },
  cardNumber3: {
    color: '#0F95A4',
  },

  cardSpan: {
    color: '#002D5C',
    fontSize: '1.625rem',
    lineHeight: 1,
    textAlign: 'center',
    fontWeight: 600,

    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
  },
  box: {
    display: 'flex',
    height: '300px',
    marginTop: '2rem',
    marginBottom: '20px',
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      height: '685px',
    },

    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },

  left: {
    flex: '2 1 auto',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    marginRight: '10px',

    [theme.breakpoints.down('sm')]: {
      marginRight: '0px',
    },
  },
  placement: {
    marginTop: '-13rem',
    [theme.breakpoints.down('sm')]: { marginTop: 0 },
  },
}));

export const CompanyStats = ({ companyStats }) => {
  const classes = useStyles();
  const med = useMediaQuery('(max-width: 960px)');

  return (
    <div className={classes.root}>
      <Container>
        <Grid
          container
          direction='row'
          justifyContent='center'
          spacing={2}
          className={classes.placement}
        >
          <div className={classes.box}>
            {companyStats.map((stat, index) => (
              <Grid
                item
                xs={12}
                lg={4}
                className={classes.card}
                container
                direction='column'
                alignItems='center'
                justifyContent='center'
                key={index}
                style={{
                  backgroundImage: `url(${stat.background?.asset?.gatsbyImageData?.images.fallback.src})`,
                }}
              >
                <PortableText
                  content={stat._rawBody}
                  serializers={{
                    h4: ({ children }) => (
                      <Typography
                        variant='h1'
                        style={{
                          lineHeight: 0.9,
                          textAlign: 'center',
                          color: index % 2 === 0 ? '#055291' : '#2a7abc',
                        }}
                      >
                        {children}
                      </Typography>
                    ),
                    normal: ({ children }) => (
                      <Typography
                        variant='body1'
                        color='primary'
                        style={{
                          fontWeight: 600,
                          fontSize: '1.625rem',
                          textAlign: 'center',
                        }}
                      >
                        {children}
                      </Typography>
                    ),
                  }}
                />
              </Grid>
            ))}
          </div>
        </Grid>
      </Container>
    </div>
  );
};
