import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import PortableText from 'react-portable-text';

import { Button, Grid, Typography, useMediaQuery } from '@material-ui/core';

export const CompanyMosaic = ({ companyMosaic, text, ctaText, link }) => {
  const lg = useMediaQuery('(max-width: 1280px)');

  return (
    <Grid
      container
      style={{ padding: lg ? '2rem 0 ' : '4rem 0' }}
      spacing={4}
      justifyContent={lg ? 'center' : 'space-between'}
      alignItems='center'
      direction='row'
    >
      <Grid item lg={7}>
        <GatsbyImage
          image={companyMosaic.asset.gatsbyImageData}
          imgStyle={{ objectFit: 'contain' }}
        />
      </Grid>
      <Grid item lg={5} style={{ textAlign: 'center' }}>
        <PortableText
          content={text}
          serializers={{
            h3: ({ children }) => (
              <Typography
                variant='h2'
                style={{
                  color: '#19305A',
                  fontWeight: 700,
                }}
              >
                {children}
              </Typography>
            ),
            h4: ({ children }) => (
              <Typography
                variant='h5'
                style={{
                  lineHeight: '32px',
                  marginBottom: '1rem',
                  fontWeight: 400,
                  color: '#5A7184',
                }}
              >
                {children}
              </Typography>
            ),
            strong: ({ children }) => (
              <span style={{ fontWeight: 500, color: '#19305A' }}>
                {children}
              </span>
            ),
          }}
        />
        <a href={link} target='_blank'>
          <Button size='large' color='secondary' variant='contained'>
            {ctaText}
          </Button>
        </a>
      </Grid>
    </Grid>
  );
};
