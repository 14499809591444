import React from 'react';
import PortableText from 'react-portable-text';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    borderRadius: '20px',
    border: '1px solid #E5EAF4',
    boxShadow: '0px 10px 40px rgba(0, 0, 0, 0.15)',
    padding: '2rem 1rem',
  },
  image: {
    [theme.breakpoints.down('sm')]: {
      margin: '1rem 0',
    },
  },
  text: {
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
}));

export const CompanyEquityPartners = ({ companyEquity }) => {
  const classes = useStyles();
  const { _rawEquityHeader, _rawLeadershipLink, equityLogos } = companyEquity;
  return (
    <Grid
      container
      direction='column'
      justifyContent='center'
      alignItems='center'
      style={{ padding: '4rem 0' }}
    >
      <Grid item style={{ textAlign: 'center' }}>
        <PortableText
          content={_rawEquityHeader}
          serializers={{
            h2: ({ children }) => (
              <Typography
                variant='h2'
                color='primary'
                style={{
                  textAlign: 'center',
                  paddingBottom: '2rem',
                  whiteSpace: 'nowrap',
                }}
              >
                {children}
              </Typography>
            ),
          }}
        />
      </Grid>
      <Grid
        item
        container
        direction='row'
        justifyContent='space-around'
        alignItems='center'
        spacing={2}
        className={classes.imageContainer}
      >
        {equityLogos.map((logo, index) => (
          <Grid
            key={index}
            container
            direction='row'
            justifyContent='center'
            item
            xs={12}
            sm={6}
            md={3}
          >
            <GatsbyImage
              image={logo.asset?.gatsbyImageData}
              className={classes.image}
            />
          </Grid>
        ))}
      </Grid>
      <Grid item style={{ paddingTop: '2rem' }}>
        <PortableText
          content={_rawLeadershipLink}
          serializers={{
            normal: ({ children }) => (
              <Link to={'/leadership/'}>
                <Button color='secondary' size='large' variant='contained'>
                  {children}
                </Button>
              </Link>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};
