import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Grid, Container, Typography, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { HeroModal } from '../../../Partners/Hero/HeroModal';
import 'animate.css/animate.min.css';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0rem 0 0rem 0',
    padding: '4rem 0',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  header: {
    fontSize: '2.5rem',
    fontWeight: 700,
    paddingBottom: '10px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.9rem',
    },
  },
  subHeader: {
    fontFamily: 'Roboto',
    //fontSize: '1.375rem',
    color: '#4B5B69',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '30px',
    },
  },
  itemContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      marginLeft: '32px',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0px',
    },
    // marginTop: '40px',
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '40px',
    zIndex: 2,

    [theme.breakpoints.down('md')]: {
      marginBottom: '30px',
    },

    '&:last-child': {
      marginBottom: '0px',
    },
  },

  text: {
    margin: 0,
    //fontFamily: ['Roboto', 'sans-serif'].join(),
  },
  line: {
    height: '96%',
    width: '10px',
    position: 'absolute',
    zIndex: 1,
    left: '35px',
    padding: '54px 0',
    borderRadius: '10px',
  },
  span: {
    height: '100%',
    width: '10px',
    display: 'block',
    position: 'relative',
    background:
      'linear-gradient( 180deg,#0f95a4 7.52%, #2a7abc 17.12%, #2a7abc 31.94%, #055291 42.15%, #055291 56.85%, #002d5c 66.57%, #002d5c 81.51%, #001933 91.11%)',
    zIndex: 1,
  },
  item1Circle: {
    background: '#0f95a4',
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    marginRight: '15px',
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid #E5EAF4',
  },
  item2Circle: {
    background: '#2a7abc',
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    marginRight: '15px',
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid #E5EAF4',
  },
  item3Circle: {
    background: '#055291',
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    marginRight: '15px',
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid #E5EAF4',
  },
  item4Circle: {
    background: '#002d5c',
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    marginRight: '15px',
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid #E5EAF4',
  },
  item5Circle: {
    background: '#001933',
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    marginRight: '15px',
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid #E5EAF4',
  },
  imgCont: {
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      marginTop: '2rem',
    },
  },

  button: {
    marginTop: '1rem',
    marginLeft: '1rem',
    transition: 'all 0.35s ease !important',
    '&:hover': {
      opacity: '0.85 !important',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
    },
  },
  '@keyframes pulseBorder': {
    '0%': {
      transform: 'translateX(-50%) translateY(-50%) translateZ(0) scale(1)',
      opacity: '1',
    },
    '100%': {
      transform: 'translateX(-50%) translateY(-50%) translateZ(0) scale(1.5)',
      opacity: '0',
    },
  },
  playButton: {
    height: '100px',
    width: '100px',
    background: '#fff',
    color: '#002d5c',
    fontSize: '1.5rem',
    borderRadius: '50%',
    position: 'absolute',
    // left: '100%',
    // marginTop: '215px',
    // marginLeft: '70px',
    // top: '44%',
    opacity: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%)',
    cursor: 'pointer',
    zIndex: 5,

    '&::before': {
      content: `''`,
      position: 'absolute',
      zIndex: '-1',
      left: '50%',
      top: '50%',
      transform: 'translateX(-50%) translateY(-50%)',
      display: 'block',
      width: '100px',
      height: '100px',
      background: '#fff',
      borderRadius: '50%',
    },

    '&:hover': {
      '& $playButtonEffect': { transform: 'scale(1.1)' },
      '&::before': {
        animation: '$pulseBorder 1500ms ease-out infinite',
      },
    },
    [theme.breakpoints.down('lg')]: {
      // marginTop: '200px',
    },
    [theme.breakpoints.down('xs')]: {
      // marginLeft: '50px',
      // marginTop: '140px',
    },
  },
}));

const ScrollItem = ({ item, index }) => {
  const classes = useStyles();

  const { header, body, icon } = item;
  return (
    <div className={classes.item}>
      <div
        data-sal='slide-up'
        data-sal-duration='200'
        data-sal-delay='100'
        data-sal-easing=''
        className={classes[`item${index}Circle`]}
      >
        <FontAwesomeIcon
          icon={['fas', icon ?? 'x-mark']}
          size='4x'
          style={{
            padding: '10px',
            color: '#fff',
          }}
        />
      </div>
      <div
        data-sal='slide-up'
        data-sal-duration='200'
        data-sal-delay='100'
        data-sal-easing=''
      >
        <Typography variant='h5' color='primary' className={classes.text}>
          {header}{' '}
        </Typography>
        <Typography variant='body1' className={classes.text}>
          {body}
        </Typography>
      </div>
    </div>
  );
};

export const CompanyIntro = ({ companyIntro }) => {
  const classes = useStyles();
  const { companyScrollItems, rightSideImage, rightSideVideo } = companyIntro;
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <div className={classes.root}>
        <Container>
          <Grid
            container
            direction='row'
            justifyContent='flex-start'
            spacing={2}
          >
            <Grid item xs={12} md={6}>
              <Grid className={classes.itemContainer}>
                <div className={classes.line}>
                  <span className={classes.span} />
                </div>
                {companyScrollItems.map((item, index) => (
                  <ScrollItem item={item} index={index + 1} />
                ))}
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              justifyContent='center'
              alignItems='center'
              container
              className={classes.imgCont}
            >
              <GatsbyImage
                image={rightSideImage.asset.gatsbyImageData}
                alt='Interview Image'
                className={classes.interviewImage}
              />
              <div
                className={classes.playButton}
                onClick={() => setModalOpen(true)}
              >
                <FontAwesomeIcon icon={['fas', 'play']} size='lg' />
              </div>
            </Grid>
          </Grid>
        </Container>
        <HeroModal
          open={modalOpen}
          setHeroModalOpen={setModalOpen}
          wistiaLink={rightSideVideo}
          playing
        />
      </div>
    </>
  );
};
