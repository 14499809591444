import React from 'react';

import { Container } from '@material-ui/core';

import { CompanyIndustryCards } from './LowerSectionComponents/CompanyIndustryCards';

import { CompanyEquityPartners } from './LowerSectionComponents/CompanyEquityPartners';
import { CompanyFeature } from './UpperSectionComponents/CompanyFeature';
import { CompanyNews } from './LowerSectionComponents/CompanyNews';
import { CompanyLocations } from './LowerSectionComponents/CompanyLocations';
import { CompanyCoreValues } from './LowerSectionComponents/CompanyCoreValues';
import { CompanyMosaic } from './LowerSectionComponents/CompanyMosaic';

export const CompanyLowerSection = ({ companyLowerSectionCombined }) => {
  const {
    companyLowerSection,
    companyLocationBg,
    companyLocationHeader,
    companyLocations,
  } = companyLowerSectionCombined;

  const companyLocation = {
    companyLocationBg,
    companyLocationHeader,
    companyLocations,
  };

  const companyLowerFeatures = companyLowerSection.companyLowerFeatures;
  const {
    _rawCoreValuesHeader,
    coreValuesSubheader,
    coreValuesImages,
    companyMosaic,
    _rawCompanyMosaicText,
    companyMosaicCtaText,
    companyMosaicCtaLink,
  } = companyLowerSection;
  return (
    <>
      <CompanyCoreValues
        values={coreValuesImages}
        header={_rawCoreValuesHeader}
        subheader={coreValuesSubheader}
      />
      <Container>
        <CompanyMosaic
          companyMosaic={companyMosaic}
          text={_rawCompanyMosaicText}
          ctaText={companyMosaicCtaText}
          link={companyMosaicCtaLink}
        />
        <CompanyEquityPartners companyEquity={companyLowerSection} />
        {companyLowerFeatures.map((feature, index) => (
          <CompanyFeature key={index} feature={feature} index={index} />
        ))}
      </Container>
      <CompanyNews companyNews={companyLowerSection} />
      <CompanyLocations companyLocation={companyLocation} />
    </>
  );
};
