import React, { useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import PortableText from 'react-portable-text';

import { Grid, Typography, makeStyles } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';

const useStyles = makeStyles((theme) => ({
  header: {
    color: '#002D5C',
    fontSize: '2.5rem',
    fontWeight: 700,
    textAlign: 'center',
    paddingBottom: '1rem',
    zIndex: 10,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.9rem',
    },
  },
  paraContent: {
    fontFamily: ['Roboto', 'sans-serif'].join(),
    zIndex: 100,
    position: 'relative',
    textAlign: 'left',
    marginBottom: '1.5rem',
    [theme.breakpoints.down('xs')]: {},
  },

  subHeader: {
    color: '#002D5C',
    fontSize: '2rem',
    fontWeight: 700,
    textAlign: 'center',
    paddingBottom: '0rem',
    zIndex: 10,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.5rem',
    },
  },
  cardImg: {
    [theme.breakpoints.down('sm')]: { maxWidth: '300px' },
  },
}));

export const MobileFeatures = ({ values, header, subheader }) => {
  const classes = useStyles();
  const [carouselOptions] = useState({
    autoPlay: true,
    animation: 'slide',
    indicators: false,
    timeout: 500,
    interval: 5000,
    navButtonsAlwaysVisible: true,
    // navButtonsAlwaysInvisible: true,
  });

  return (
    <Grid
      container
      direction='column'
      justifyContent='center'
      alignItems='center'
      spacing={4}
      style={{ paddingTop: '2rem' }}
    >
      <Grid item>
        <PortableText
          content={header}
          serializers={{
            h4: ({ children }) => (
              <Typography variant='h2' color='primary'>
                {children}
              </Typography>
            ),
            strong: ({ children }) => (
              <>
                <span
                  style={{
                    textTransform: 'uppercase',
                    color: '#2a7abc',
                    fontWeight: 700,
                  }}
                >
                  {children}
                </span>
                <br />
              </>
            ),
          }}
        />

        <Typography
          variant='h4'
          className={classes.paraContent}
          style={{
            textAlign: 'left',
            lineHeight: '38px',
            color: '#5a7184',
            fontWeight: 400,
            marginTop: '15px',
          }}
        >
          {subheader}
        </Typography>
        <Carousel
          style={{ width: '100%' }}
          autoPlay={carouselOptions.autoPlay}
          animation={carouselOptions.animation}
          indicators={carouselOptions.indicators}
          indicatorContainerProps={{
            style: {
              marginBottom: '16px',
            },
          }}
          interval={carouselOptions.interval}
          timeout={carouselOptions.timeout}
          navButtonsAlwaysVisible={carouselOptions.navButtonsAlwaysVisible}
          navButtonsAlwaysInvisible={carouselOptions.navButtonsAlwaysInvisible}
          navButtonsProps={{
            style: {
              backgroundColor: '#002D5C',
              marginBottom: '5rem',
              padding: '5px',
            },
          }}
        >
          {values.map((value, index) => (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                height: 'auto',
              }}
              key={index}
            >
              <GatsbyImage
                className={classes.cardImg}
                image={value.coreValueImage.asset.gatsbyImageData}
                alt='Core Value'
              />
              <Typography
                variant='h5'
                style={{
                  color: '#002D5C',
                  textAlign: 'center',
                }}
              >
                {value.coreValueTitle}
              </Typography>
              <Typography
                variant='body1'
                className={classes.cardBody}
                style={{ textAlign: 'center' }}
              >
                {value.coreValueDescription}
              </Typography>
            </div>
          ))}
        </Carousel>
      </Grid>
    </Grid>
  );
};
