import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Container,
  Grid,
  Typography,
  makeStyles,
  Card,
} from '@material-ui/core';

export const CompanyIndustry = () => {
  const industries = [
    {
      name: 'Commercial Cleaning & Janitorial',
      color: '#2f7fc1',
    },
    {
      name: 'Lawn Care & Landscaping',
      color: '#0F95A4',
    },
    {
      name: 'Pest Control',
      color: '#055291',
    },
    {
      name: 'Security Guarding',
      color: '#2f7fc1',
    },
  ];

  const useStyles = makeStyles((theme) => ({
    text: {
      fontWeight: 700,
      marginLeft: '10px',
    },
    card: {
      height: '80px',
      borderRadius: '14px',
      width: '95%',
      [theme.breakpoints.down('sm')]: { width: '80%', marginBottom: '20px' },
    },
  }));
  const classes = useStyles();

  return (
    <Container style={{ padding: '4rem 0' }}>
      <Grid container justifyContent='center' style={{ paddingBottom: '2rem' }}>
        <Typography variant='h2' color='primary'>
          Industries We Serve
        </Typography>
      </Grid>

      <Grid container direction='row'>
        {industries.map((industry, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            lg={3}
            key={index}
            container
            justifyContent='center'
          >
            <Card className={classes.card} elevation={3}>
              <Grid
                container
                style={{ height: '100%' }}
                justifyContent='flex-start'
                alignItems='center'
              >
                <Grid item xs={2} lg={1}>
                  <FontAwesomeIcon
                    icon={['fas', 'circle-check']}
                    size='lg'
                    style={{
                      marginRight: '.5rem',
                      fontWeight: 700,
                      color: industry.color,
                    }}
                  />
                </Grid>
                <Grid item xs={10} lg={11}>
                  <Typography
                    variant='body1'
                    color='primary'
                    className={classes.text}
                  >
                    {industry.name}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};
