import React from 'react';

import {
  Container,
  Typography,
  Grid,
  useMediaQuery,
  makeStyles,
} from '@material-ui/core';

import { MobileFeatures } from './Mobile/MobileFeatures';
import { DesktopFeatures } from './DesktopFeatures';

const useStyles = makeStyles((theme) => ({
  header: {
    textAlign: 'center',
    marginBottom: '2rem',
    fontSize: '2.25rem',
    [theme.breakpoints.down('sm')]: {
      margin: '0',
    },
  },
}));

export const CenterFeatures = ({
  values,
  header,
  subheader,
  setSelected,
  selected,
}) => {
  const md = useMediaQuery('(max-width: 960px)');
  const classes = useStyles();
  return (
    <Container>
      {md ? (
        <MobileFeatures
          values={values}
          selected={selected}
          setSelected={setSelected}
          header={header}
          subheader={subheader}
        />
      ) : !!selected ? (
        <DesktopFeatures
          values={values}
          setSelected={setSelected}
          selected={selected}
          header={header}
          subheader={subheader}
        />
      ) : null}
    </Container>
  );
};
