import React, { useState, useEffect } from 'react';

import { useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { WaveUpSVG } from '../../../../WaveSVGs/WaveUpSVG';
import { WaveDownSVG } from '../../../../WaveSVGs/WaveDownSVG';
import { CenterFeatures } from './CenterFeatures';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    background: '#f5f9ff',
    borderTop: '10px solid #fff',
    borderBottom: '10px solid #fff',
  },

  background: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    background: 'transparent',
    position: 'absolute',
    // zIndex: 1,
    top: '0',
    padding: '1rem 0',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  centerFeatures: {
    background: 'transparent',
    position: 'relative',
    zIndex: 3,
    padding: '4rem 0',

    [theme.breakpoints.down('sm')]: {
      padding: '6rem 0 8rem 0',
    },
  },
}));

export const CompanyCoreValues = ({ values, header, subheader }) => {
  const classes = useStyles();

  const [selected, setSelected] = useState(null);

  useEffect(() => {
    setSelected(values[0]);
  }, []);

  const med = useMediaQuery('(max-width: 960px)');
  return (
    <div className={classes.root}>
      <div style={{ marginTop: '-5rem' }}>
        <WaveDownSVG fill='#FFF' shadow style={{ zIndex: -1 }} />
      </div>

      <div className={classes.centerFeatures}>
        <CenterFeatures
          values={values}
          header={header}
          subheader={subheader}
          setSelected={setSelected}
          selected={selected}
          style={{ position: 'relative', zIndex: 3 }}
        />
      </div>
      <div style={{ marginBottom: '-2rem' }}>
        <WaveUpSVG fill='#FFF' shadow style={{ zIndex: 99 }} />
      </div>
    </div>
  );
};
