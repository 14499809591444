import React, { useState, useEffect } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import {
  Grid,
  Container,
  Typography,
  useMediaQuery,
  Button,
  Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PortableText from 'react-portable-text';

const useStyles = makeStyles((theme) => ({
  root: {},

  centerFeatures: {
    background: 'transparent',
    padding: '14rem 0 16rem',
    [theme.breakpoints.down('sm')]: {
      padding: '8rem 0 12rem 0',
    },
  },

  subHeader: {
    fontFamily: 'Roboto',
    fontSize: '1.3125rem',
    fontWeight: 500,
    textAlign: 'left',
    color: '#4B5B69',
    paddingBottom: '10px',
  },

  header: {
    color: '#002D5C',
    fontSize: '2.5rem',
    fontWeight: 700,
    textAlign: 'left',
    paddingBottom: '1rem',
    zIndex: 10,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.9rem',
    },
  },

  paraContent: {
    fontFamily: ['Roboto', 'sans-serif'].join(),
    zIndex: 100,
    position: 'relative',
    textAlign: 'center',
    marginBottom: '1.5rem',
    [theme.breakpoints.down('xs')]: {},
  },

  card: {
    opacity: 1,
    background: 'white',
    marginBottom: '2rem',
    padding: '2rem',
    borderRadius: '20px',
    border: '2px solid #E5EAF4',
    boxShadow: '0 10px 40px 0px rgba(0,0,0,0.10)',
  },

  cardHeader: {
    color: '#002D5C',
    fontSize: '2.5rem',
    fontWeight: 700,
    textAlign: 'center',
    paddingBottom: '1rem',

    [theme.breakpoints.down('xs')]: {
      fontSize: '1.9rem',
    },
  },

  cardImg: {
    maxWidth: '280px',
    margin: '0 auto',
    display: 'block',
  },
}));

export const DesktopFeatures = ({
  values,
  selected,
  setSelected,
  header,
  subheader,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.centerFeatures}>
        <Container>
          <Grid
            container
            alignItems='flex-start'
            justifyContent='space-between'
            spacing={4}
            style={{ position: 'relative' }}
          >
            <Grid
              container
              direction='column'
              item
              xs={5}
              style={{ position: 'sticky', top: '30%', height: 'auto' }}
            >
              <PortableText
                content={header}
                serializers={{
                  h4: ({ children }) => (
                    <Typography variant='h2' color='primary'>
                      {children}
                    </Typography>
                  ),
                  strong: ({ children }) => (
                    <>
                      <span
                        style={{
                          textTransform: 'uppercase',
                          color: '#2a7abc',
                          fontWeight: 700,
                        }}
                      >
                        {children}
                      </span>
                      <br />
                    </>
                  ),
                }}
              />

              <Typography
                variant='h4'
                className={classes.paraContent}
                style={{
                  textAlign: 'left',
                  lineHeight: '38px',
                  color: '#5a7184',
                  fontWeight: 400,
                  marginTop: '15px',
                }}
              >
                {subheader}
              </Typography>
            </Grid>

            <Grid
              container
              justifyContent='flex-start'
              alignItems='center'
              item
              xs={7}
              spacing={2}
            >
              {values.map((value, index) => (
                <div
                  className={classes.card}
                  style={{ width: '100%', height: '500px' }}
                  key={index}
                >
                  <Typography variant='h2' className={classes.cardHeader}>
                    {value.coreValueTitle}
                  </Typography>

                  <Typography variant='body1' className={classes.paraContent}>
                    {value.coreValueDescription}
                  </Typography>
                  <GatsbyImage
                    className={classes.cardImg}
                    image={value.coreValueImage.asset.gatsbyImageData}
                    alt='Core Value'
                  />
                </div>
              ))}
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};
